.App {
  text-align: center;
  padding: 2rem 1rem;
  font-family: "Jost", sans-serif;
}

.Links > a {
  display: inline-block;
  padding: 0 0.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Enriqueta", serif;
}
